type routes = {
  [key: string]: string
}
const routes: routes = {
  dashboard: '/dashboard',
  goals: '/goals',
  goalsAdd: '/goals/add',
  goalsEdit: '/goals/edit',
  assets: '/assets',
  assetsAdd: '/assets/add',
  assetsEdit: '/assets/edit',
  debts: '/debts',
  debtsAdd: '/debts/add',
  debtsEdit: '/debts/edit',
  netwealth: '/netwealth',
  insurance: '/insurance',
  contactUs: '/contact-us',
  landing: '/landing',
  welcome: '/welcome',
  register: '/register',
  polices: '/polices',
  profile: '/profile',
  facebook: 'https://www.facebook.com/lifetimeadviceforlife/',
  linkedin: 'https://www.linkedin.com/company/lifetime-group-ltd-/',
  callback: '/callback',
  login: '/login',
  loginPending: '/login/pending',
  logout: '/logout',
}

export default routes

export const getPageFromUrl = (url: string) => {
  const page = Object.keys(routes).find((key) => routes[key] === url)
  if (page) {
    return page.charAt(0).toUpperCase() + page.slice(1)
  }
  return 'Unknown'
}
